@charset "UTF-8";
iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}

html {
  background: url(../img/bg1.png);
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
textarea,
blockquote,
th,
td,
label,
span,
strong,
p {
  margin: 0px;
  padding: 0px;
  color: #666666;
  font-size: 12px;
  font-family: "Microsoft Yahei", 宋体, Arial, Verdana;
}

body {
  margin: 0px;
  padding: 0px;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

body.ready .body-wrapper {
  opacity: 1;
}

.hide {
  display: none;
}

img {
  border: 0px;
}

a {
  outline: none;
}
a.color-link {
  color: var(--primaryColor);
}
a.color-link:hover {
  text-decoration: underline;
}

input,
select {
  height: 16px;
  vertical-align: middle;
}

fieldset {
  margin-top: 15px;
  padding: 10px 0px;
  width: 698px;
  border: 1px solid #999999;
  overflow: hidden;
}
fieldset legend {
  margin: 0px 12px;
  padding: 2px 5px;
  color: #000;
  font-size: 14px;
}
fieldset table {
  margin-top: 0px;
}
fieldset table th,
fieldset table td {
  padding: 2px 5px;
}
fieldset table th {
  padding: 2px 0px;
  width: 150px;
  text-align: right;
  font-weight: normal;
}
fieldset table th span {
  margin: 0px 5px;
  color: #ff0000;
  font-weight: bold;
}

/*input[type="submit"],input[type="button"],input[type="reset"] { cursor:pointer;font-family:宋体,Verdana,Arial; }

*/
table {
  border-collapse: collapse;
  border-spacing: 0px;
}

/*::selection { background:#950a03;color:#fff;text-shadow:none; }*/
a:link, a:visited {
  color: #666666;
  text-decoration: none;
}
a:hover {
  color: #ff0000;
  text-decoration: none;
}
a.black:link, a.black:visited {
  color: #666666;
  text-decoration: underline;
}
a.black:hover {
  color: #ff0000;
  text-decoration: underline;
}
a.red:link, a.red:visited {
  color: red;
  text-decoration: none;
}
a.red:hover {
  color: #af0101;
  text-decoration: none;
}
a.red2:link, a.red2:visited {
  color: red;
  text-decoration: underline;
}
a.red2:hover {
  color: #af0101;
  text-decoration: underline;
}
a.blue:link, a.blue:visited {
  color: blue;
  text-decoration: none;
}
a.blue:hover {
  color: #0101af;
  text-decoration: none;
}

.noRecord {
  height: 50px;
  line-height: 50px;
  color: #ff7200;
  text-align: center;
}

.form-tips {
  color: #eeb7b7;
}

.Loding {
  height: 16px;
  padding: 13px 0px 0px 30px;
  color: #a8ff00;
  text-align: left;
}

.imeDisable {
  ime-mode: disabled;
}

.errTips {
  display: none;
  margin-left: 5px;
  padding: 0px 10px 1px 5px;
  line-height: 20px;
  border: 2px solid #ffb4b7;
  background-color: #fbe3e4;
  color: #ff0000;
  font-style: italic;
}

.ok-tips {
  display: block;
  padding: 2px 5px;
  background: url(../img/ico-ok.gif) no-repeat left center;
  color: green;
  text-indent: 15px;
}

.form-send-tips {
  display: none;
  line-height: 24px;
  border: 1px solid #fdd6bd;
  background: #ffeee2 url(http://404ident331.com/) no-repeat 5px 5px;
  border-radius: 3px;
  text-indent: 25px;
  color: #ff6600;
}

.form-send-tips-err {
  border: 1px solid #f7bda5;
  background: #ffded0 url(http://404ident331.com/) no-repeat 6px 6px;
  color: #ff4e00;
}

.form-send-tips-ok {
  border: 1px solid #99ec70;
  background: #d8f5c9 url(../img/ico-ok.gif) no-repeat 6px 6px;
  color: #3ab100;
}
.form-send-tips-ok span {
  color: #ff0000;
  font-weight: bold;
}

/*返回顶部*/
#floatTop {
  background: url(http://404ident331.com/) no-repeat;
  cursor: pointer;
  display: none;
  position: fixed;
  z-index: 20;
  right: 30px;
  bottom: 30px;
  width: 42px;
  height: 43px;
}

.ui-top-nav {
  width: 270px;
  height: auto;
  background: url(../img/login_win.png) no-repeat;
  float: left;
}
.ui-top-nav dl {
  width: 100%;
  overflow: hidden;
}
.ui-top-nav dl dt {
  float: left;
  width: 435px;
  height: 45px;
  line-height: 45px;
  background: url(http://404ident331.com/) no-repeat left center;
  overflow: hidden;
}
.ui-top-nav dl dt span {
  float: left;
  display: block;
  width: 72px;
  height: 100%;
  text-align: right;
  color: #b6985a;
}
.ui-top-nav dl dt marquee {
  float: right;
  width: 360px;
  height: 100%;
}
.ui-web-nav {
  position: fixed;
  z-index: 100;
  left: 0px;
  top: 46px;
  width: 100%;
  height: 87px;
  background: url(http://404ident331.com/) no-repeat center bottom;
}
.ui-web-nav dl {
  margin: 0 auto;
  width: 1000px;
  overflow: hidden;
}
.ui-web-nav dl dt {
  float: left;
  width: 250px;
  overflow: hidden;
}
.ui-web-nav dl dd {
  float: right;
  padding-top: 36px;
  height: 33px;
  overflow: hidden;
}
.ui-web-nav dl dd a:link, .ui-web-nav dl dd a:visited {
  float: left;
  display: block;
  width: 101px;
  height: 33px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.ui-web-nav dl dd a:hover, .ui-web-nav dl dd a.cur {
  background: url(http://404ident331.com/) no-repeat center top;
  color: #640b26;
}

.login-frm {
  width: 100%;
  height: 250px;
  padding-top: 60px;
}

/*.login-frm div { position:relative; margin:0px 2px; width: 110px;height:24px;background:#fff; }
.login-frm div label { float:left;cursor:text;position:absolute;left:5px;top:0px;line-height:24px;color:#c2c2c2;font-size:14px; }
.login-frm div img { cursor:pointer;float:right;margin:1px 1px 0px 0px;width:50px;height:22px; }
.login-frm input { outline:none;float:left;border:0px;padding:0px;height:24px;line-height:24px;background:#fff;color:#666666;font-size:14px; }
.login-frm .txt-box { padding-left:5px;width:90px; }
.login-frm .txt-box2 { padding-left:5px; width: 40px; }
.login-frm #loginSubmitBtn { cursor:pointer;margin-left:2px;width:50px;height:24px;background:url(../img/login-button.jpg) no-repeat center center; }
.login-frm a { float:left;margin-left:10px;line-height:24px;color:#fff; }
.login-frm a:hover { color:#ffcc00; }
.login-frm a.forgot-pwd { margin:0px; }*/
.ui-footer {
  padding-top: 35px;
  width: 100%;
  min-width: 1000px;
  height: 90px;
  background: url(http://404ident331.com/) repeat-x;
}
.ui-footer dl {
  margin: 0px auto;
  width: 1000px;
  overflow: hidden;
}
.ui-footer dl dt {
  height: 40px;
  text-align: center;
  color: #c79697;
  overflow: hidden;
}
.ui-footer dl dt a {
  margin: 0px 10px;
  color: #c79697;
}
.ui-footer dl dt a:hover {
  color: #fff;
}
.ui-footer dl dd {
  height: 23px;
  background: url(http://404ident331.com/) no-repeat center top;
}

.footer-img {
  width: 100%;
  min-width: 1000px;
  height: 430px;
  background: url(http://404ident331.com/) no-repeat center top;
}

.nav {
  margin: 0 auto;
  height: 18px;
  background: #ae1a14;
}

.hua {
  width: 213px;
  height: 256px;
  position: absolute;
  left: 0;
  top: 115px;
  background: url(../img/hua.png);
}

.foot {
  height: 152px;
  background: url(../img/luo1.png) no-repeat;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.foot img {
  display: block;
  position: fixed;
  display: block;
  margin-top: 8px;
  left: 1140px;
}

/*首页*/
#homeBanner {
  position: relative;
  width: 100%;
  min-width: 1000px;
  height: 541px;
  background: url(../img/line.jpg) no-repeat center bottom;
  overflow: hidden;
}
#homeBanner .banner {
  position: absolute;
  z-index: 1px;
  width: 100%;
  min-width: 1000px;
  height: 537px;
}
#homeBanner .ctrl {
  margin: -50px 0px 0px -480px;
  left: 50%;
  top: 50%;
  width: 940px;
  height: 100px;
  position: absolute;
  z-index: 999;
}
#homeBanner .ctrl a {
  background: url(http://404ident331.com/) no-repeat;
  width: 30px;
  height: 100px;
  display: block;
  position: absolute;
  z-index: 999;
  opacity: 0.1;
  -moz-opacity: 0.1;
  overflow: hidden;
  text-indent: -200px;
}
#homeBanner .ctrl a:hover {
  opacity: 0.5;
  -moz-opacity: 0.5;
}
#homeBanner .ctrl a#prev {
  left: -30px;
}
#homeBanner .ctrl a#next {
  background-position: -30px 0px;
  right: -30px;
}

.home-lottery {
  width: 100%;
  padding-top: 40px;
  height: 350px;
  background: url(../img/main-box-bg.jpg) no-repeat center top;
}
.home-lottery div {
  margin: 0 auto;
  width: 1000px;
  height: 300px;
}
.home-lottery div a {
  float: left;
  display: block;
  width: 250px;
  height: 150px;
  overflow: hidden;
  text-indent: -500px;
}
.home-lottery div a.game1, .home-lottery div a.game2, .home-lottery div a.game3, .home-lottery div a.game4, .home-lottery div a.game5, .home-lottery div a.game6, .home-lottery div a.game7, .home-lottery div a.game8 {
  background: url(http://404ident331.com/) no-repeat;
}

/*栏目*/
.menu-main-warp {
  position: relative;
  margin-top: 98px;
  width: 100%;
  min-width: 1000px;
  background: url(http://404ident331.com/) repeat-y;
  overflow: hidden;
}

.menu-main-l {
  position: absolute;
  left: 0px;
  padding-top: 30px;
  width: 200px;
  overflow: hidden;
}
.menu-main-l ul {
  list-style: none;
  overflow: hidden;
}
.menu-main-l ul li {
  float: left;
  margin: 0px 10px;
  height: 36px;
  line-height: 36px;
  border-bottom: 1px solid #d2d1d1;
  font-size: 14px;
}
.menu-main-l ul li a {
  float: left;
  padding-left: 20px;
  display: block;
  width: 166px;
  height: 34px;
  color: #333333;
}
.menu-main-l ul li a:hover {
  background: url(http://404ident331.com/) no-repeat;
}
.menu-main-l ul li a.cur {
  background: url(http://404ident331.com/) no-repeat;
}
.menu-main-l ul li a.cur:hover {
  background: url(http://404ident331.com/) no-repeat;
}

.menu-main-r {
  margin-left: 200px;
  padding: 20px;
  overflow: hidden;
}

.menu-title {
  line-height: 45px;
  border-bottom: 2px solid #d2d1d1;
  font-size: 18px;
  overflow: hidden;
}

.menu-title2 {
  width: 700px;
  line-height: 45px;
  border-bottom: 2px solid #d2d1d1;
  font-size: 18px;
}

.agent-frm h1 {
  margin-top: 10px;
  font-size: 20px;
}

/*Info Content*/
.info-cont {
  padding-top: 15px;
  line-height: 2em;
  white-space: normal;
  text-justify: inter-ideograph;
  text-align: justify;
}
.info-cont h1 {
  margin-top: 10px;
  font-size: 20px;
}
.info-cont h2 {
  margin-top: 10px;
  font-size: 16px;
}
.info-cont h3 {
  margin-top: 10px;
  font-size: 14px;
}
.info-cont h4 {
  margin-top: 10px;
}
.info-cont p {
  margin: 10px 0px 0px 0px;
}

/*代理加盟*/
.apply-class {
  margin-left: 40px;
  padding: 20px 0px 0px 0px;
  width: 918px;
  height: 35px;
}
.apply-class a {
  float: left;
  display: block;
  margin-right: 10px;
  padding: 0px 20px;
  height: 25px;
  line-height: 25px;
  border: 1px solid #ffbd5b;
  background: #331304;
  color: #ffeed6;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.apply-class a:hover {
  border: 1px solid #ffbd5b;
  background: #b0521e;
  color: #fff;
}
.apply-class a.seled {
  border: 1px solid #ffbd5b;
  background: #b0521e;
  color: #fff;
}
.apply-class a.seled:hover {
  color: #3e220f;
}

.apply-tab {
  width: 100%;
  border: 0px;
  border-top: 1px solid #1e63bd;
  border-left: 1px solid #1e63bd;
}
.apply-tab th,
.apply-tab td {
  line-height: 30px;
  border: 0px;
  border-right: 1px solid #1e63bd;
  border-bottom: 1px solid #1e63bd;
  background: #003374;
  text-align: center;
}
.apply-tab th {
  background: #002658;
  color: #fff;
}

/*规则说明*/
.rule-info {
  margin: 25px auto 30px auto;
  width: 940px;
  overflow: hidden;
}
.rule-info ul {
  width: 940px;
  overflow: hidden;
  list-style: none;
}
.rule-info ul li {
  float: left;
  margin-top: 5px;
  width: 940px;
  overflow: hidden;
}
.rule-info ul li img {
  float: left;
  width: 940px;
}
.rule-info ul li div {
  float: left;
}
.rule-info ul li .min-banner {
  width: 940px;
  cursor: pointer;
}
.rule-info .rule-cont {
  display: none;
  padding: 5px 0px;
  line-height: 2em;
  overflow: hidden;
}

/*手机投注*/
.mobile {
  margin: 0px auto;
  width: 1000px;
  height: 480px;
  overflow: hidden;
  /*background:#8e6617;box-shadow:0px 0px 10px #242001;*/
}
.mobile embed {
  float: left;
}

input.inp-txt {
  height: 20px;
  line-height: 20px;
  padding: 0px 4px;
  border: 1px solid #707070;
  /*box-shadow:inset 2px 2px 4px #145bba;*/
}
input.inp-txt:hover {
  border-color: #fea201;
}
input.btnFace {
  cursor: pointer;
  width: 80px;
  height: 26px;
  line-height: 23px;
  *line-height: 15px;
  _line-height: 15px;
  border: 1px solid #053a09;
  background: url(http://404ident331.com/) repeat-x;
  color: #2f0a01;
}
input.inp-rd {
  margin-right: 5px;
}
input.rdFace {
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  width: 12px;
  height: 12px;
  *width: 14px;
  *height: 14px;
  border: 1px solid #5799f2;
  background: #186dde;
}
input.rdFace-over {
  border: 1px solid #ff8400;
  box-shadow: 0px 0px 7px #c9c9c9;
}

/*INPUT.btnFace-over,INPUT.btnFace2-over,INPUT.btnFace3-over,INPUT.btnFace:hover,INPUT.btnFace2:hover { background:url(../img/btnFace.gif) no-repeat 0px -114px;border:1px solid #FFB400;color:#FF0000;box-shadow:0px 0px 7px #c9c9c9; }*/
textarea {
  padding: 4px;
  border: 1px solid #707070;
  overflow-y: auto;
}
textarea:hover {
  border-color: #fea201;
}

select {
  padding: 2px;
  height: 22px;
  border: 1px solid #707070;
}

.reg-btn,
.ag-btn {
  cursor: pointer;
  width: 90px;
  height: 28px;
  border: 1px solid #707070;
  background: url(http://404ident331.com/) repeat-x;
  color: #707070;
}
.reg-btn:hover,
.ag-btn:hover {
  border: 1px solid #fea201;
  background-position: left bottom;
  color: #990000;
}

.header {
  width: 950px;
  height: 100px;
  padding-top: 10px;
  margin: 0 auto 10px;
  position: relative;
}

.logo {
  width: 250px;
  height: 100px;
  position: absolute;
  left: -10px;
  top: 10px;
}

.notice {
  height: 30px;
  margin-top: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-left: 270px;
  position: relative;
}

.notice-div {
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.notice-div span {
  width: 80px;
  height: 30px;
  line-height: 30px;
  color: #cf1717;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
  float: left;
}
.notice-div div {
  color: #fff;
}

.scrollText {
  width: 590px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  float: left;
}
.scrollText li {
  font-size: 14px;
  color: #cf1717;
}

.notice-bg {
  width: 100%;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.3;
  filter: alpha(opacity=30);
  border-radius: 5px;
  z-index: -1;
}

.menu {
  height: 30px;
  line-height: 30px;
  margin-left: 250px;
  clear: both;
}
.menu ul {
  height: 30px;
  list-style: none;
  float: right;
}
.menu li {
  width: 90px;
  height: 30px;
  line-height: 30px;
  list-style: none;
  text-align: center;
  float: left;
}
.menu li a {
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: block;
  color: #84230c;
  font-size: 16px;
  font-weight: bold;
}
.menu li a:hover {
  color: #fff;
}

.main {
  width: 950px;
  height: 400px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.swiper-container {
  float: left;
  height: 341px;
  width: 650px;
  margin: 0 15px;
}
.swiper-container .swiper-pagination .swiper-pagination-bullet {
  background-color: #fff !important;
}

.swiper-slide {
  overflow: hidden;
}
.swiper-slide .img-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(10px) grayscale(50%);
  z-index: 0;
}
.swiper-slide img {
  height: 100%;
  width: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.login-ipt {
  background: url("../img/input_bg.png") no-repeat left top;
  height: 50px;
  width: 220px;
  margin: 10px auto;
  position: relative;
}
.login-ipt span.txt-box {
  line-height: 45px;
}

.username label {
  background: url("../img/login_ico.png") no-repeat top;
  display: block !important;
  font-size: 0;
  text-indent: -99999px;
  height: 35px;
  width: 38px;
  margin: 6px 0px 0 10px;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  float: left;
}
.input-txt {
  background: none !important;
}

.password label {
  background: url("../img/login_ico.png") no-repeat center;
  display: block !important;
  font-size: 0;
  text-indent: -99999px;
  height: 35px;
  width: 38px;
  margin: 6px 0px 0 10px;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  float: left;
}
.code label {
  background: url("../img/login_ico.png") no-repeat bottom;
  display: block !important;
  font-size: 0;
  text-indent: -99999px;
  height: 35px;
  width: 38px;
  margin: 6px 0px 0 10px;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  float: left;
}

.login-ipt input {
  float: left;
  font-size: 16px;
  margin: 12px 0 0 0;
  width: 155px;
  color: #999;
  border: none;
  height: 25px;
  line-height: 25px;
}

.login-ipt .verify-code {
  position: absolute;
  height: 28px;
  top: 9px;
  left: 130px;
}

.reg {
  padding-left: 30px;
  clear: both;
}
.reg.after-login span {
  color: #fff;
}
.reg.after-login a {
  color: #fff;
}

.login-btn {
  width: 120px;
  height: 52px;
  background: url(../img/login_bu.png) no-repeat left top;
  border: none;
  text-indent: -9999em;
  float: left;
  cursor: pointer;
}
.login-btn:hover {
  background: url(../img/login_bu.png) no-repeat left -52px;
}

.zhuce {
  width: 88px;
  height: 52px;
  background: url(../img/login_bu.png) no-repeat left -104px;
  display: block;
  text-indent: -9999em;
  float: left;
  margin-left: 8px;
}
.zhuce:hover {
  background: url(../img/login_bu.png) no-repeat left -156px;
}

.test {
  cursor: pointer;
  width: 221px;
  height: 46px;
  background: url(../img/login_bu.png) no-repeat left -208px;
  border: none;
  text-indent: -9999em;
  float: left;
}
.test:hover {
  background: url(../img/login_bu.png) no-repeat left -254px;
}

.forgot-pwd {
  position: absolute;
  right: -22px;
  top: 10px;
}

.box {
  padding: 10px;
  line-height: 24px;
}
.box:not(.promo) strong {
  height: 30px;
  line-height: 30px;
  display: block;
  padding-top: 10px;
}

.daili {
  width: 112px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #992a2c;
  background: #992a2c;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  border-radius: 3px;
  font-family: "Microsoft YaHei";
  display: block;
  margin: 0 auto;
}
.daili:link, .daili:visited {
  color: #fff;
}

.promotitle {
  font-size: 14px;
  font-weight: bolder;
}

.promopanel {
  margin-bottom: 20px;
}

.kf_t {
  width: 119px;
  border: 1px solid #fff;
  text-align: center;
  position: fixed;
  left: 50%;
  margin-left: 500px;
  top: 100px;
}
.kf_t div {
  position: relative;
  margin-top: 5px;
}
.kf_t .img-desc {
  position: absolute;
  top: -20px;
  left: -10px;
  transform: translateX(-100%);
  display: none;
}
.kf_t .kefuapp {
  position: relative;
}
.kf_t .kefuapp .desc {
  background-image: linear-gradient(to bottom, #ae511c, #843d15);
  border-radius: 20px;
  color: #fff;
  display: block;
  font-size: 16px;
  margin: auto;
  width: 92%;
}
.kf_t .kefuapp .img-desc {
  background-color: #fff;
  left: -65px;
  padding: 5px;
}
.kf_t .kefuapp .qr-code {
  height: unset;
  width: unset;
  position: unset;
}

.appqr {
  background: url(../img/k_vx.png) center/contain no-repeat;
  height: 166px;
}

.appqr img {
  position: absolute;
  width: 93px;
  top: 58px;
  right: 13px;
}

.appqr .qr-code {
  top: 48px;
  width: 100px;
  height: 100px;
}

.appqr .qr-code canvas {
  width: 100% !important;
  height: 100% !important;
}

.appqr p {
  color: #000;
  font-size: 15px;
  font-weight: bold;
}

.alert-box-wrapper div,
.alert-box-wrapper span {
  color: #fff;
}

a.applink:hover {
  border: 2px dotted #dce6f4;
  padding: 2px;
  background-color: #ffff00;
  color: green;
  text-decoration: none;
}

a.applink {
  border: 2px dotted #dce6f4;
  padding: 2px;
  color: #2f5bff;
  background: transparent;
  text-decoration: none;
}

a.info {
  color: #2f5bff;
  background: transparent;
  text-decoration: none;
}

a.info:hover {
  color: green;
  background: transparent;
  text-decoration: underline;
}

a.supportDropdown {
  position: absolute;
  left: 165px;
  top: 20px;
}

.cooperation-row {
  font-size: 16px;
  line-height: 28px;
}
.cooperation-row .cooperation-row-title {
  color: #990000;
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px;
}
.cooperation-row .cooperation-list {
  display: flex;
}
.cooperation-row .cooperation-list > div {
  text-align: left;
}
.cooperation-row .cooperation-list .cooperation-title {
  flex-basis: 150px;
  font-weight: bold;
}
.cooperation-row .cooperation-list .cooperation-title:after {
  content: ":";
}
.cooperation-row .cooperation-list .cooperation-qrcode {
  width: 150px;
  background: white;
}