iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: 1000;

  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}
